

































































































































































































































































































.vs-navbar__group__items .vs-navbar__item.active::before {
  opacity: 0 !important;
}
